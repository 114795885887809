import store from '@/store'
// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export function checkPermission(permission) {
  const canAccess = store.getters['user/hasPermission']

  return canAccess(permission)
}

export default function userModel(force = false) {
  const profile = async () => {
    let profileData = store.state.user.profile

    if (!profileData || force) {
      await axios
        .get('/admin/user/profile/me')
        .then(response => {
          store.commit('user/SET_PROFILE_MODEL', response.data.user)
          store.commit('user/SET_PERMISSIONS', response.data.permissions)
          profileData = store.state.user.profile
        })
    }

    return profileData
  }

  return {
    profile,
  }
}
