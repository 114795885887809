import { mdiAlertOctagon } from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'Система',
  },
  {
    title: 'Сообщения в шапке',
    icon: mdiAlertOctagon,
    to: 'system-notifications',
    permission: PERMISSIONS.SYSTEM_NOTIFICATIONS_READ,
  },
]
