const routes = [
  {
    path: '/system/notifications',
    name: 'system-notifications',
    component: () => import('@/views/system/notifications/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default routes
