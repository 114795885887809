import analytics from './analytics'
import invoices from './invoices'
import settings from './settings'
import avatars from './avatars'
import appearance from './appearance'
import audioMeditations from './audio-meditations'
import ai from './ai'
import geolocation from './geolocation'
import system from './system'

export default [...analytics, ...invoices, ...settings, ...avatars, ...appearance, ...audioMeditations, ...ai, ...geolocation, ...system]
